// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/app/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/app/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/app/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/app/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/app/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1696779083000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "Environment Prepare",
                  "heading": "environment-prepare"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 3,
                  "value": "Build project",
                  "heading": "build-project"
                },
                {
                  "depth": 3,
                  "value": "Check code style",
                  "heading": "check-code-style"
                },
                {
                  "depth": 3,
                  "value": "Test code",
                  "heading": "test-code"
                },
                {
                  "depth": 2,
                  "value": "More",
                  "heading": "more"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/app/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1696779083000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/dashboard",
        "name": "首页看板",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/app/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user",
        "icon": "user",
        "name": "用户",
        "routes": [
          {
            "path": "/user/list",
            "name": "用户列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__list' */'/app/src/pages/user/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/trialList",
            "name": "体验用户列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__trialList' */'/app/src/pages/user/trialList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/company/list",
            "name": "企业资源管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__list' */'/app/src/pages/company/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/company/usage_list",
            "name": "企业资源使用列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__usage_list' */'/app/src/pages/company/usage_list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/course",
        "icon": "user",
        "name": "课程",
        "routes": [
          {
            "path": "/course/task/list",
            "name": "任务列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__course__task__list' */'/app/src/pages/course/task/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/course/records/list",
            "name": "练习记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__course__records__list' */'/app/src/pages/course/records/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/course/records/dialogueDetails/:id",
            "name": "对话详情",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__course__records__dialogueDetails' */'/app/src/pages/course/records/dialogueDetails'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/course/courses/list",
            "name": "课程列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__course__courses__list' */'/app/src/pages/course/courses/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/course/courses/questionDetail/:id",
            "name": "问答详情",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__course__courses__questionDetail' */'/app/src/pages/course/courses/questionDetail'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/package",
        "icon": "deployment-unit",
        "name": "套餐资源列表",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__package' */'/app/src/pages/package'), loading: LoadingComponent}),
        "access": "canAdmin",
        "exact": true
      },
      {
        "path": "/score",
        "icon": "deployment-unit",
        "name": "评分能力模型",
        "routes": [
          {
            "path": "/score/list",
            "name": "评分列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__score__list' */'/app/src/pages/score/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/score/dimension",
            "name": "维度列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__score__dimension' */'/app/src/pages/score/dimension'), loading: LoadingComponent}),
            "access": "canAdmin",
            "exact": true
          }
        ]
      },
      {
        "path": "/feedback",
        "icon": "deployment-unit",
        "name": "建议反馈列表",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__feedback' */'/app/src/pages/feedback'), loading: LoadingComponent}),
        "access": "canAdmin",
        "exact": true
      },
      {
        "path": "/graph",
        "icon": "deployment-unit",
        "name": "知识图谱",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__graph__list' */'/app/src/pages/graph/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/graph/nodes/:id",
        "icon": "deployment-unit",
        "name": "节点列表",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__graph__node_list' */'/app/src/pages/graph/node_list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/graph/detail/:id",
        "icon": "deployment-unit",
        "name": "图谱详情",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__graph__detail' */'/app/src/pages/graph/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/pe",
        "icon": "deployment-unit",
        "name": "Prompt 工程",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pe' */'/app/src/pages/pe'), loading: LoadingComponent}),
        "access": "canAdmin",
        "exact": true
      },
      {
        "path": "/account",
        "icon": "user",
        "name": "账户管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account' */'/app/src/pages/account'), loading: LoadingComponent}),
        "access": "canAdmin",
        "exact": true
      },
      {
        "path": "/dictionary",
        "icon": "user",
        "name": "字典管理",
        "routes": [
          {
            "path": "/dictionary/list",
            "name": "字典列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dictionary__dictlist' */'/app/src/pages/dictionary/dictlist'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/dictionary/dictdiscription/:id",
            "name": "字典详情",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dictionary__dictdiscription' */'/app/src/pages/dictionary/dictdiscription'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "routes": [
          {
            "path": "/login",
            "layout": false,
            "name": "登录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'/app/src/pages/login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
