export const ACCESS_TOKEN = 'access_token';

export const Models = [
  { label: 'gpt-4', value: 'gpt-4' },
  { label: 'gpt-4-o', value: 'gpt-4-o' },
  { label: 'gpt-4o-mini', value: 'gpt-4o-mini' },
  { label: 'gpt-4-1106-preview', value: 'gpt-4-1106-preview' },
  { label: 'deepseek-chat', value: 'deepseek-chat' },
  { label: 'doubao-128k', value: 'ep-20240803175247-r7gjf' },
  { label: 'doubao-32k', value: 'ep-20240803175220-d8tnm' },
  // { label: 'MiniMax', value: 'minimax' },
  // { label: '百度千帆', value: 'bdqianfan' },
  // { label: '智谱AI GLM-4', value: 'zhipuai-glm-4' },
  // { label: '通义千问', value: 'qwen-turbo' },
];

export const VoiceModels = [
  { label: 'Max', value: 'MAX' },
  // { label: 'MAX-男声', value: 'MAX-男声' },
  // { label: 'Maner-女声-安丹', value: 'Maner-女声-安丹' },
  { label: 'HS', value: 'Maner' },
];

export const AsrModels = [
  { label: '16k_zh', value: '16k_zh' },
  { label: '16k_zh_large', value: '16k_zh_large' },
  { label: '16k_en', value: '16k_en' },
];
